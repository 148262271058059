import { toNameInitials } from '../services/converting-string-service';

export class User {
  exp: number;
  iat: number;
  sub: string;
  userId: number;
  userLogin: string;
  userName: string;
  email: string;
  clientId: number;
  clientName: string;
  permissions: string[];
  profiles: [];
  applicationKey: string;

  constructor({
    exp,
    iat,
    sub,
    userId,
    userName,
    email,
    clientId,
    clientName,
    permissions,
    profiles,
    applicationKey,
  }: User) {
    this.exp = exp;
    this.iat = iat;
    this.sub = sub;
    this.userId = userId;
    this.userName = userName;
    this.email = email;
    this.clientId = clientId;
    this.clientName = clientName;
    this.permissions = permissions;
    this.profiles = profiles;
    this.applicationKey = applicationKey;
  }

  nameInitials(): string {
    return toNameInitials(this.userName ?? '');
  }
}
