import axios, { AxiosError, Axios } from 'axios';
import { VueRouter } from 'vue-router';
import { navigateToUrl } from 'single-spa';

import { getCookie, removeCookie, setCookie } from './cookies-service';
import { setLastRoute } from './router-service';
import { generateAxiosInstance } from './interceptors-service';

const { getBackendDomain } = require('@zitrus/z-login-utils');

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

/**
 * Send a request o backend to try to refresh token and execute the routines for success or failure.
 *
 * @param {string} refreshToken
 */
export const refreshTokenService = async (refreshToken: string): Promise<string> => {
  const axiosRefresh = generateAxiosInstance(axios, getBackendDomain());
  const url = '/login/refreshtoken';

  return new Promise((resolve, reject) =>
    axiosRefresh
      .post<RefreshTokenResponse>(url, { refreshToken })
      .then(({ data }) => {
        setCookie('token', data?.accessToken);
        setCookie('refreshToken', data?.refreshToken);

        resolve(data?.accessToken);
      })
      .catch((error) => reject(error)),
  );
};

/**
 * Execute the routine to log out the user that can not refresh the token
 *
 * @param {AxiosError} error
 * @param {VueRouter} router
 */
const logOutTokenExpiredUser = (error: AxiosError, router?: VueRouter) => {
  const isLoginRoute = router?.currentRoute?.fullPath === '/login';
  const isNoPermissionRoute = router?.options?.base?.includes('usuario-sem-permissao');

  removeCookie('token');
  removeCookie('refreshToken');

  if (router && !isLoginRoute && !isNoPermissionRoute) {
    setLastRoute(`${router?.options?.base}${router?.currentRoute?.path}`, 5);
    navigateToUrl('/login');
  }

  return Promise.reject(error);
};

/**
 * Control the 401 error from API, and execute the routine for refresh the user token.
 *
 * @param {AxiosError} error
 * @param {Axios} axios
 * @param {VueRouter} router
 */
const responseError401 = async (error: AxiosError, axios: Axios, router?: VueRouter) => {
  const refreshToken = getCookie('refreshToken');
  const isTokenExpired = error?.response?.data['message']
    ?.toLowerCase()
    ?.includes('token is expired');
  const isLoginRoute = router?.currentRoute?.fullPath === '/login';

  if (isLoginRoute) {
    return Promise.reject(error);
  }

  if (isTokenExpired && refreshToken) {
    try {
      const newAccessToken = await refreshTokenService(refreshToken);
      error.config.headers.Authorization = `Bearer ${newAccessToken}`;

      return axios.request(error.config);
    } catch (error) {
      return logOutTokenExpiredUser(error, router);
    }
  }

  return logOutTokenExpiredUser(error, router);
};

export default responseError401;
