import axios, { Axios, AxiosError, AxiosResponse } from 'axios';
import { VueRouter } from 'vue-router';
import { getUserState } from './user-service';
import { defineHttpInterceptors, generateAxiosInstance } from './interceptors-service';
import { noPermissionPath } from './router-service';

const { getPermissionDomain } = require('@zitrus/z-login-utils');

type ErrorActionType = {
  [key: string]: (router?: VueRouter) => Promise<void | AxiosResponse | null>;
};

const reloadMsg = `It's necessary to reload user permission`.toLowerCase();
const noPermissionMsg = 'user does not have permission for this request'.toLowerCase();

/**
 * Execute the routine to refresh user permissions, as he changes the app context.
 *
 * @param {VueRouter} router
 * @param {string} applicationKey
 */
export const refreshUserPermissionsAPI = async (
  router?: VueRouter,
  applicationKey?: string,
): Promise<AxiosResponse | null> => {
  const appKey = applicationKey ? applicationKey : getUserState()?.applicationKey;
  const url = `/aplicacao/${appKey}/usuario-perfil/carregar/permissoes`;
  const axiosRefresh = generateAxiosInstance(axios, getPermissionDomain());

  defineHttpInterceptors(axiosRefresh, router);

  return await axiosRefresh.get(url);
};

/**
 * Redirect user to the no permission path.
 *
 * @param {VueRouter} router
 */
const redirectNoPermission = async (router: VueRouter): Promise<void> => {
  await router.push({ path: `/${noPermissionPath}` });

  return Promise.reject({
    data: { ignoreError: true, message: 'Você não tem permissão para está ação' },
  });
};

/**
 * Object to map error messages, for a function call.
 *
 * @type {ErrorActionType}
 */
const errorActions: ErrorActionType = {
  [reloadMsg]: refreshUserPermissionsAPI,
  [noPermissionMsg]: redirectNoPermission,
};

/**
 * Control the 403 error from API, and execute the routine for reload the user permission, or show that he doesn't have the permission for the action.
 * The messages reference, came from Kong. So if they change the message on the plugin, we need to change here to.
 *
 * @param {AxiosError} error
 * @param {Axios} axios
 * @param {VueRouter} router
 */
const responseError403 = async (error: AxiosError, axios: Axios, router?: VueRouter) => {
  const errorMsg = error?.response?.data['detail']
    ? error?.response?.data['detail']?.toLowerCase()
    : error?.response?.data['message']?.toLowerCase() ??
      error?.response?.data['title']?.toLowerCase();
  const action = errorActions[errorMsg];

  return action ? await action(router) : Promise.reject(error);
};

export default responseError403;
