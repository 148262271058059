import jwtDecode from 'jwt-decode';
import { NavigationGuardNext, Route, VueRouter } from 'vue-router';
import { VueMock } from 'vue-mock';
import { refreshUserPermissionsAPI } from './interceptors-403-error-service';
import { noPermissionPath } from './router-service';
import { AxiosResponse } from 'axios';
import {
  PermissionPluginData,
  PluginOptions,
  UserAppPermission,
  UserAppPermissionJwt,
} from '../models/user-app-permissions-model';
import { getUserState } from './user-service';

const ignoredApps = ['workspace', 'liberador'];
const routesNoPermissionCheck = ['/login'];
const graphql = 'graphql';
const fixPermissionActionRgx = new RegExp(/^(.*):(.*)$/, 'gm');

const skipVerifyPermission = (options: PluginOptions, to: Route): boolean => {
  const { ignoreRouterEnter } = options;
  const { meta } = to;
  const { emptyState, ignorePermission, applicationKey } = meta;

  return (
    ignoredApps.includes(applicationKey) ||
    Boolean(ignoreRouterEnter) ||
    Boolean(ignorePermission) ||
    Boolean(emptyState)
  );
};

const hasToUpdatePermissions = (data: PermissionPluginData, to: Route): boolean => {
  const user = getUserState();
  const { meta } = to;
  const { currentUser, currentClient, lastAppKey } = data;

  if (!user) {
    return false;
  }

  return (
    user?.userLogin !== currentUser ||
    user?.clientId !== currentClient ||
    lastAppKey !== meta.applicationKey
  );
};

const formatPermissions = (userPermissions: Array<string>, lastAppKey: string) =>
  userPermissions.map((permission: string) => {
    if (permission.includes(graphql)) {
      return graphql;
    }

    return permission
      .replace(`${lastAppKey}.`, '')
      .replace('menu_', '')
      .replace(/_/g, '-')
      .replace(fixPermissionActionRgx, '$2-$1')
      .trim();
  });

const onReloadPermissionError = async (
  router: VueRouter,
  next?: NavigationGuardNext,
): Promise<void> => {
  await router.push({ path: `/${noPermissionPath}` });

  next && next();

  return;
};

const checkPermission = async (
  options: PluginOptions,
  to: Route,
  data: PermissionPluginData,
  next?: NavigationGuardNext,
): Promise<void> => {
  const { currentAppProfile } = data;
  const { formattedPermissions } = currentAppProfile;
  const { fullPath, meta, name } = to;

  const correctName = meta?.fatherRoute ? meta.fatherRoute : name;
  const pathName =
    meta.action === 'view' && !correctName.includes('view-') ? `view-${correctName}` : correctName;

  if (!routesNoPermissionCheck.includes(fullPath) && !formattedPermissions.includes(pathName)) {
    const { router } = options;

    await router.push({ path: `/${noPermissionPath}` });
  }

  next && next();
};

const updatePermissions = async (
  data: PermissionPluginData,
  to: Route,
  options: PluginOptions,
  next?: NavigationGuardNext,
) => {
  data.lastAppKey = to.meta.applicationKey;
  data.currentUser = getUserState()?.userLogin;
  data.currentClient = getUserState()?.clientId;

  const { router } = options;
  const { lastAppKey } = data;
  let permissionData = null;

  try {
    const response: AxiosResponse<UserAppPermissionJwt> = await refreshUserPermissionsAPI(
      router,
      data.lastAppKey,
    );

    permissionData = response?.data;
  } catch (error) {
    return onReloadPermissionError(router, next);
  }

  const decodedPermission = jwtDecode(permissionData?.permissions) as UserAppPermission;
  const permissions = decodedPermission?.profiles[0]?.permissions || [];

  data.currentAppProfile = {
    permissions,
    formattedPermissions: Array.isArray(decodedPermission?.profiles[0]?.permissions)
      ? formatPermissions(permissions, lastAppKey)
      : [],
  };
};

export const UserAppPermissionPlugin = {
  install(Vue: VueMock, options: PluginOptions) {
    const data: PermissionPluginData = {
      lastAppKey: '',
      currentUser: '',
      currentClient: null,
      currentAppProfile: { permissions: [], formattedPermissions: [] },
    };
    Vue.prototype.getUserPermissionData = () => data;

    Vue.mixin({
      async beforeRouteEnter(to: Route, from: Route, next?: NavigationGuardNext) {
        if (skipVerifyPermission(options, to)) {
          next && next();
          return;
        }

        if (hasToUpdatePermissions(data, to)) {
          await updatePermissions(data, to, options, next);
        }

        await checkPermission(options, to, data, next);
      },
    });
  },
};
