const toKebab = (text: string) => text.replace(/[_\s]+/g, '-');

const toCamel = (text: string) =>
  toKebab(text)
    .split('-')
    .map((word, index) => {
      if (index === 0) return word;
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');

const toTitle = (text: string) =>
  toKebab(text)
    .split('-')
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(' ');

const toSentence = (text: string) => {
  const interim = toKebab(text).replace(/-/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
};

const toNormalLettersWithoutSpecialChar = (text: string) => {
  const firstSpace = text.indexOf(' ');
  const newString = firstSpace > 0 ? text.slice(0, firstSpace) : text;
  const newStringWithoutSpecialCharacters = newString
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return newStringWithoutSpecialCharacters.toLowerCase();
};

const toNameInitials = (fullName: string) => {
  if (!fullName) {
    return '';
  }

  const userSplit = fullName?.split(' ') || '';
  const name = userSplit[0] == null ? null : userSplit[0]?.substring(0, 1)?.toUpperCase();
  const lastName =
    userSplit.length <= 1 && userSplit[userSplit.length - 1] == null
      ? null
      : userSplit[userSplit.length - 1]?.substring(0, 1)?.toUpperCase();

  return `${name ?? ''}${lastName ?? ''}`;
};

export { toKebab, toCamel, toTitle, toSentence, toNormalLettersWithoutSpecialChar, toNameInitials };
