import {
  Axios,
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosStatic,
  InternalAxiosRequestConfig,
} from 'axios';
import { VueRouter } from 'vue-router';

import { getCookie, removeCookie } from './cookies-service';
import responseError403 from './interceptors-403-error-service';
import responseError401, { refreshTokenService } from './interceptors-401-error-service';
import { getUserState } from './user-service';

type ProcessQueueAction = { error?: unknown | null | undefined; token?: string | null | undefined };
type FailedQueueType = { resolve: (value: unknown) => void; reject: (value: unknown) => void };
type ErrorActionType = {
  [key: number]: (
    error: AxiosError,
    axios: Axios,
    router?: VueRouter,
  ) => Promise<AxiosResponse<unknown> | void>;
};
type UnauthorizedResponseType = { detail: string };

let isRefreshing = false;
let failedQueue: Array<FailedQueueType> = [];

/**
 * Object to map all the status code, for a function call.
 *
 * @type {ErrorActionType}
 */
const errorActions: ErrorActionType = {
  401: responseError401,
  403: responseError403,
};

/**
 * Add token on header for the request that will be made.
 *
 * @param {InternalAxiosRequestConfig} config
 */
export const requestInterceptorConfig = (config: InternalAxiosRequestConfig) => {
  const token = getCookie('token');
  if (token !== undefined && token?.length > 0) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

/**
 * Execute all the requests in queue, if it has an error or a success response.
 *
 * @param {ProcessQueueAction} options
 */
const processQueue = ({ error, token }: ProcessQueueAction) => {
  failedQueue.forEach((p) => (error ? p.reject(error) : p.resolve(token)));

  failedQueue = [];
};

/**
 * Try to execute the refresh token and re-run the request. If is already has a refresh token in execution, the function
 * add the request to queue, to be executed after the refresh is resolved.
 *
 * @param {InternalAxiosRequestConfig} config
 */
const axiosRefreshToken = async (config: InternalAxiosRequestConfig): Promise<void | never> => {
  isRefreshing = true;

  try {
    const refreshToken = getCookie('refreshToken');
    const newAccessToken = await refreshTokenService(refreshToken);

    config.headers.Authorization = `Bearer ${newAccessToken}`;

    processQueue({ token: newAccessToken });
  } catch (e) {
    const error: AxiosError<UnauthorizedResponseType> = {
      ...e,
      response: {
        ...e?.response,
        status: 401,
        statusText: 'Unauthorized',
        data: { detail: 'Token de acesso expirou!' },
      },
    };

    removeCookie('token');
    removeCookie('refreshToken');

    processQueue({ error });

    return Promise.reject(error);
  } finally {
    isRefreshing = false;
  }
};

/**
 * Control the response error from API, and execute the right routine for the error status code.
 *
 * @param {InternalAxiosRequestConfig} config
 */
const requestInterceptorRefreshToken = async (config: InternalAxiosRequestConfig) => {
  const user = getUserState();
  const currentTime = Date.now() / 1000;

  if (user && user.exp < currentTime) {
    if (!isRefreshing) {
      axiosRefreshToken(config).then(() => {});
    }

    return new Promise((resolve, reject) => failedQueue.push({ resolve, reject })).then((token) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  return config;
};

/**
 * Control the response error from API, and execute the right routine for the error status code.
 *
 * @param {AxiosError} error
 * @param {Axios} axios
 * @param {VueRouter} router
 */
const responseErrorConfig = async (error: AxiosError, axios: Axios, router?: VueRouter) => {
  const status = error?.response?.status;
  const action = errorActions[status];

  return action ? await action(error, axios, router) : Promise.reject(error);
};

/**
 * Exported function that create a basic axios instance with the same pattern in all services.
 *
 * @param {AxiosStatic} axios
 * @param {string} baseURL
 */
export const generateAxiosInstance = (axios: AxiosStatic, baseURL: string): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

/**
 * Exposed function for the services, that set the correct configurations for axios instance.
 *
 * @param {Axios} axios
 * @param {VueRouter} router
 */
export function defineHttpInterceptors(axios: Axios, router?: VueRouter) {
  axios.interceptors.request.use((config) => requestInterceptorConfig(config));

  axios.interceptors.request.use(async (config) => await requestInterceptorRefreshToken(config));

  axios.interceptors.response.use(
    (response) => response,
    async (error) => responseErrorConfig(error, axios, router),
  );
}
